/**
 * 监听文档visibilitychange事件
 */
const OnVisible = {
    inserted: function (el, binding) {
        const callback = binding.value;
    
        const handleVisibilityChange = () => {
            if (!document.hidden) {
                callback();
            }

            console.log('document.visibilityState')
        };
    
        document.addEventListener('visibilitychange', handleVisibilityChange);
    
        // 当元素被销毁时，移除事件监听器
        el._onVisibleDestroy = () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    },
    unbind: function (el) {
        // 元素被销毁时，移除事件监听器
        if (el._onVisibleDestroy) {
            el._onVisibleDestroy();
        }
    },
}

export default OnVisible